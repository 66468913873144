<template>
  <base-missing-data :icon="$options.IconMissingDataEmployees" class="clients-missing-data" :message="message" />
</template>

<script>
// Constants
import * as typesClient from '@/constants/typesClients';

// Components
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataEmployees from '@/components/MissingDataIcons/MissingDataEmployees.vue';

export default {
  name: 'ClientsMissingData',

  components: { BaseMissingData },

  props: {
    typeClient: { type: String, required: true },
  },

  computed: {
    isOwner() {
      return this.typeClient === typesClient.OWNER;
    },
    isTenants() {
      return this.typeClient === typesClient.TENANTS;
    },

    message() {
      return this.isOwner ? this.$t('clients.owner_missing_message') : this.$t('clients.tenants_missing_message');
    },
  },

  IconMissingDataEmployees,
};
</script>
