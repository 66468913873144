<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="media.isMobile && canUpdate" :to="clientRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_client') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="exportClients">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>

      <file-input v-if="canUpdate" v-slot="{ on, attrs }" @input="importClients">
        <!--
          @click.prevent необходим для избежания двойного вызова окна загрузки input'а, 
          @click.stop не позволяет добиться этого поведения 
        -->
        <v-list-item :disabled="isProcessing" v-bind="attrs" v-on="on" @click.prevent>
          <v-list-item-title>
            <v-icon right>mdi-file-upload</v-icon>
            {{ $t('button.import') }}
          </v-list-item-title>
        </v-list-item>
      </file-input>

      <v-list-item v-if="canUpdate" @click="unarchiveClients">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-up</v-icon>
          {{ $t('button.unarchive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canUpdate" @click="archiveClients">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-down</v-icon>
          {{ $t('button.archive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!isShortTenantsType" @click="resentEmail">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-multiple-marked</v-icon>
          {{ $t('button.reset_password_email') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="media.isMobile" @click="selectAll">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-marked</v-icon>
          {{ $t('button.select_all') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Constants
import { CLIENTS_CREATE } from '@/constants/routes';
import * as typesClient from '@/constants/typesClients';

// Components
import IconButton from '@/components/IconButton.vue';
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'ClientsMenu',

  components: { IconButton, FileInput },

  inject: ['media'],

  props: {
    typeClient: { type: String, required: true },
    page: { type: Number, default: 1 },
    isProcessing: { type: Boolean, default: false },
    canUpdate: { type: Boolean, default: false },
  },

  computed: {
    clientRoute() {
      return { name: CLIENTS_CREATE, query: { prevPage: this.page } };
    },
    isShortTenantsType() {
      return this.typeClient === typesClient.SHORT_TENANTS;
    },
  },

  methods: {
    exportClients() {
      this.$emit('export-clients');
    },

    importClients(file) {
      this.$emit('import-clients', file);
    },

    unarchiveClients() {
      this.$emit('unarchive-clients');
    },

    archiveClients() {
      this.$emit('archive-clients');
    },

    resentEmail() {
      this.$emit('resent-email');
    },

    selectAll() {
      this.$emit('select-all');
    },
  },
};
</script>
