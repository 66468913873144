<template>
  <base-modal v-model="modelWrapper" content-class="clients-mobile-filter" :title="$t('clients.filter')">
    <clients-filters v-model="localFilters" :type-client="typeClient" />

    <v-row class="mt-8" no-gutters>
      <v-col class="pr-1 pr-md-2" :cols="6">
        <v-btn block elevation="0" large color="secondary" class="primary--text" @click="closeModal">{{
          $t('modal_button.back')
        }}</v-btn>
      </v-col>

      <v-col class="pl-1 pl-md-2" :cols="6">
        <v-btn block large elevation="0" color="primary" @click="saveFilter">{{ $t('modal_button.save') }}</v-btn>
      </v-col>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import ClientsFilters from '@/components/Clients/Filters.vue';

import { clone } from '@/utils/clone';

export default {
  name: 'ClientsModalFilters',
  components: { BaseModal, ClientsFilters },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },

  props: {
    isOpen: { type: Boolean, default: false },
    filters: { type: Object, required: true },
    typeClient: { type: String, required: true },
  },

  data() {
    return {
      localFilters: {},
    };
  },
  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        this.$emit('update:isOpen', value);
      },
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localFilters = clone(this.filters);
        }
      },
    },
  },
  methods: {
    saveFilter() {
      this.$emit('update:filters', clone(this.localFilters));
      this.closeModal();
    },
    closeModal() {
      this.modelWrapper = false;
    },
  },
};
</script>

<style lang="scss">
.clients-mobile-filter {
  max-width: 360px !important;
}
</style>
